import * as React from 'react'

import logo from '../img/logo.svg'
import Social from './Social'
import gatsby from '../img/social/gatsby.svg'
import netlify from '../img/social/netlify.svg'

const Footer = () => {
  return (
    <footer className={`global-footer`}>
      <div className={`logo`}>
        <img src={logo} alt="Meincken" />
      </div>
      {/* <section className="menu">
          <ul className="menu-list">
            <li>
              <Link to="/" className="navbar-item">
                Home
              </Link>
            </li>
            <li>
              <a
                className="navbar-item"
                href="/admin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Admin
              </a>
            </li>
          </ul>
          <ul className="menu-list">
            <li>
              <Link className="navbar-item" to="/blog">
                Latest Stories
              </Link>
            </li>
          </ul>
        </section> */}
      <Social />
      <small className={`builtwith`}>
        Built with
        <img src={gatsby} alt="Gatsby" />
        &
        <img src={netlify} alt="Netlify" />
      </small>
      <small className="center copyright">&copy;{new Date().getFullYear()} Nick Meincken</small>
    </footer>
  )
}

export default Footer
