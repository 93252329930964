import * as React from 'react'
import instagram from '../img/social/instagram.svg'
import github from '../img/social/github.svg'
import linkedin from '../img/social/linkedin.svg'

const Social = () => (
  <div className="social">
    <a title="instagram" href="https://instagram.com/meincken" target="_blank" rel="noreferrer">
      <img src={instagram} alt="Instagram" />
    </a>
    <a title="github" href="https://github.com/meincken" target="_blank" rel="noreferrer">
      <img src={github} alt="Github" />
    </a>
    <a title="linkedin" href="https://www.linkedin.com/in/meincken/" target="_blank" rel="noreferrer">
      <img src={linkedin} alt="LinkedIn" />
    </a>
  </div>
)

export default Social
