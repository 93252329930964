import React, { useState } from 'react'
import { Link } from 'gatsby'
import github from '../img/github-icon.svg'
import logo from '../img/logo.svg'

const Navbar = () => {
  const [isActive, setIsActive] = useState(false)

  return (
    <header className="header">
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="Meincken" style={{ width: '150px' }} />
          </Link>
          {/* Hamburger menu */}
          <button
            className={`navbar-burger burger ${isActive && 'is-active'}`}
            aria-expanded={isActive}
            onClick={() => setIsActive(!isActive)}
            aria-label="navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40"
              height="40"
              viewBox="0 0 50 50"
            >
              <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
            </svg>
          </button>
        </div>
        <ul
          id="navMenu"
          className={` navbar-start has-text-centered navbar-menu ${isActive && 'is-active'}`}
        >
          <li className="navbar-item">
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
          </li>
          <li className="navbar-item">
            <Link className="navbar-item" to="/portfolio">
              Portfolio
            </Link>
          </li>
          {/*
          <li className="navbar-item">
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </li> */}
          <li className="navbar-end has-text-centered">
            <a
              className="navbar-item"
              href="https://github.com/meincken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <img src={github} alt="Github" />
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Navbar
